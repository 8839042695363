import { useState, useEffect } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { applyInstance } from "../../../services/api";

import { GENERAL_ERROR } from "../../../constants/ToastMessages";
import "../../Pages.css";
import RegionSelection from "../../../components/region-selection";
import useToastContext from "../../../context/useToastContext";
import useAuthContext from "../../../context/useAuthContext";

const LoginForm = ({ setMode, completeLogin, setCognitoUser }) => {
    const { login, setLogin, clearLoginSecrets } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const { addToast } = useToastContext();

    useEffect(() => {
        clearLoginSecrets();
    }, []);

    const handleInputChange = (e, item) => {
        setLogin((prev) => ({ ...prev, [item]: e.target.value }));
    };

    const handleSubmitLoginForm = async (event) => {
        try {
            event.preventDefault();

            if (!login || !login.username || !login.password || !login.instance) {
                addToast("warning", "Missing Details", "Please fill out all fields");
                return;
            }

            setIsLoading(true);
            applyInstance(login.instance);
            const cognitoUser = await Auth.signIn(login.username, login.password);
            setCognitoUser(cognitoUser);
            if (!cognitoUser) {
                addToast("error", "Error", "Unable to login, try again later");
                return;
            }

            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                setMode("newpassword");
                return;
            }

            completeLogin(cognitoUser.attributes?.sub, login.instance);
        } catch (err) {
            if (err.code === "PasswordResetRequiredException") {
                setMode("resetpassword");
            }
            console.error(err);
            addToast("error", "Error", err.message || GENERAL_ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmitLoginForm}>
            <p className="form-title"> Sign in with your username and password </p>
            <RegionSelection />
            <div className="user-inputs">
                <Input type="text" label="Username" value={login?.username || ""} onChange={(e) => handleInputChange(e, "username")} autoComplete="username" />
            </div>
            <div className="user-inputs">
                <Input type="password" label="Password" value={login?.password || ""} onChange={(e) => handleInputChange(e, "password")} autoComplete="current-password" />
                <p className="login-forgot-password">
                    <Link to="/forgotPassword"> Forgot your password? </Link>
                </p>
            </div>
            <div className="login-signup-button">
                <Button
                    type="submit"
                    label={<>Sign in {isLoading ? <Spinner size="small" /> : null}</>}
                    variant="brand"
                    disabled={isLoading || !login?.username || !login?.password || !login?.instance}
                    style={{ width: "100%" }}
                />
            </div>
            <nav className="login-signup-link">
                <p>
                    Need an account? <Link to="/signup">Sign up</Link>
                </p>
            </nav>
        </form>
    );
};

export default LoginForm;
