import React from "react";

import "./Field.css";
import EditButtonIcon from "../EditButtonIcon";
import UndoButtonIcon from "../UndoButtonIcon";
import { RecordTypeMap } from "../../types";
import { revertOverride, setOverride } from "../../utils";
import FieldSkeleton from "../FieldSkeleton";
import FormItemWrapper from "./FormItemWrapper";

interface FieldProps<T extends keyof RecordTypeMap> {
    recordObject?: T;
    value: string | number | boolean | JSX.Element | JSX.Element[];
    label: string;
    isEditable?: boolean;
    body?: JSX.Element;
    onEdit?: () => void;
    fieldName?: string; // to revert
    mode: string;
    hasOverride?: boolean;
    showStaticViewInEditMode?: boolean; // this is for non editable fields to show in edit mode
    showStaticViewInNewMode?: boolean;
    hasRevert?: boolean;
    isFormItem?: boolean;
    setRecord?: React.Dispatch<React.SetStateAction<RecordTypeMap[T]>>;
    setFieldErrors?: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    checkValidity?: (value: string) => string;

    revertFunction?: Function;
}

// Functional component
const Field = <T extends keyof RecordTypeMap>({
    value,
    label,
    isEditable = false,
    body = <></>,
    onEdit,
    fieldName,
    mode,
    showStaticViewInEditMode = false,
    showStaticViewInNewMode = false,
    hasOverride = false,
    isFormItem = false,
    hasRevert = false,
    setRecord,
    checkValidity,
    setFieldErrors,
    revertFunction,
}: FieldProps<T>): JSX.Element => {
    const handleRevert = (field: string) => {
        if (revertFunction) {
            revertFunction(field);
            return;
        }

        setRecord((prevRecord) => {
            return revertOverride(prevRecord, field);
        });
        setFieldErrors((prev) => ({ ...prev, [field]: "" }));
    };

    // Handle dynamic field change
    const handleFieldChange = (value: string) => {
        if (checkValidity) {
            const errorMessage = checkValidity(value);
            setFieldErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
        }

        setRecord((prev) => {
            const record = hasRevert ? setOverride(prev, fieldName) : prev;
            return { ...record, [fieldName]: value };
        });
    };

    const checkFieldValidity = () => {
        if (checkValidity) {
            const valueAsString = typeof value === "string" ? value : (value ?? "").toString();
            const errorMessage = checkValidity(valueAsString);
            setFieldErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
        }
    };

    const isEditOrNew = mode === "new" || mode === "edit";

    // view mode
    const renderStaticView = () => (
        <div id="FormDiv" className={`slds-form-element_edit slds-form-element_stacked slds-hint-parent ${isEditOrNew && showStaticViewInEditMode ? "" : "slds-form-element_readonly"}`}>
            <span className="slds-form-element__label">{label}</span>
            <div className="slds-form-element__control">
                <div className="slds-form-element__static"> {value}</div>
                {isEditable && mode === "view" && <EditButtonIcon handleEdit={onEdit} />}
            </div>
        </div>
    );

    // edit and new mode
    const renderBody = () => (
        <div style={{ display: "flex", width: "100%" }}>
            <div className="slds-form-element slds-form-element_stacked" onBlur={checkFieldValidity}>
                {setRecord
                    ? React.cloneElement(body as React.ReactElement, {
                          onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFieldChange(e.target.value),
                      })
                    : body}
            </div>
            {mode === "edit" && hasOverride && <UndoButtonIcon handleRevert={handleRevert} field={fieldName} />}
        </div>
    );

    const renderContent = () => {
        switch (mode) {
            case "init":
                return <FieldSkeleton />;
            case "new":
                return showStaticViewInNewMode ? renderStaticView() : renderBody();
            case "edit":
                return showStaticViewInEditMode ? renderStaticView() : renderBody();
            default:
                return renderStaticView();
        }
    };

    return isFormItem ? renderContent() : <FormItemWrapper>{renderContent()}</FormItemWrapper>;
};

export default Field;
