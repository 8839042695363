import React, { useState } from "react";
import { Dropdown, IconSettings, Input } from "@salesforce/design-system-react";

import PsRecord2 from "../ps-record/PsRecord2";
import { ContainerResponseType, ContainerType, Mode, PsRecordPropsType, UpdateContainerRequestType } from "../../types";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Field from "../../ui/wrappers/Field";
import Toggle from "../../ui/Toggle";
import useToastContext from "../../context/useToastContext";
import { doActionAPI } from "../../services/api";
import { toastDetails, formattedDateTime, formattedPercentage } from "../../utils";
import { checkRequiredField } from "../../utils/index2";

type DropdownOption = {
    label: string;
    value: string;
    leftIcon: {
        category: string;
        name: string;
    };
};

const recordObject = "container";

const PsContainer2: React.FC<PsRecordPropsType> = ({ recordId, parentId, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<ContainerType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });

    // global toast
    const { addToast } = useToastContext();

    const parseResponse = (response: ContainerResponseType[]): ContainerType[] => {
        return response.map(({ id, name, source, relevance, dataLastChangedOn, numRows, overridden, scheduleReset }) => ({
            id,
            name: name || "",
            sourceId: source.id,
            sourceName: source.name,
            relevance: formattedPercentage(relevance),
            dataLastChangedOn: formattedDateTime(dataLastChangedOn),
            numRows,
            overridden,
            scheduleReset: !!scheduleReset,
        }));
    };

    // This overrides the default PsRecord function
    const parseUpdateRequest = (updatedRecord: ContainerType): UpdateContainerRequestType => {
        return (({ id, name, overridden, scheduleReset }) => ({
            id,
            name,
            overridden,
            scheduleReset,
        }))(updatedRecord);
    };

    const doAction = (action: string, heading: string, details: string) => {
        setLoading(true);

        const onSuccess = function () {
            addToast("info", heading, details);
            setLoading(false);
        };

        const onError = function (response: any) {
            addToast("error", "Error", toastDetails(response));
            setLoading(false);
        };

        // doActionAPI({ module: "store", object: "data", action, args: { id: recordId } }, onSuccess, onError); // not yet supported
        doActionAPI({ module: "store", object: "data", action, args: {} }, onSuccess, onError);
    };

    const handleAction = (action: string) => {
        const actionMap: Record<string, { heading: string; details: string }> = {
            supply: {
                heading: "Data Processing Started",
                details: "Started processing data, this may take a few moments",
            },
            conform: {
                heading: "Join Finding Started",
                details: "Started searching for Joins, this may take a few moments",
            },
            tack: {
                heading: "Path Finding Started",
                details: "Started searching for Paths, this may take a few moments",
            },
        };

        const selectedAction = actionMap[action];
        if (selectedAction) {
            doAction(action, selectedAction.heading, selectedAction.details);
        }
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    const cardActions =
        mode === "view" ? (
            <IconSettings iconPath="/assets/icons">
                <Dropdown
                    assistiveText={{ icon: "More Options" }}
                    iconCategory="utility"
                    iconName="down"
                    iconVariant="border-filled"
                    onSelect={(selected: DropdownOption) => handleAction(selected.value)}
                    options={[
                        {
                            label: "Process Data",
                            value: "supply",
                            leftIcon: {
                                category: "utility",
                                name: "database",
                            },
                        },
                        {
                            label: "Find Joins",
                            value: "conform",
                            leftIcon: {
                                category: "utility",
                                name: "linked",
                            },
                        },
                        {
                            label: "Find Paths",
                            value: "tack",
                            leftIcon: {
                                category: "utility",
                                name: "data_model",
                            },
                        },
                    ]}
                    width="xx-small"
                    align="right"
                    menuAlignment="right"
                    className="slds-var-m-horizontal_xxx-small"
                    alternativeText="Actions"
                />
            </IconSettings>
        ) : null;

    const cardBody = (
        <div className="slds-form slds-var-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Object Details</h3>
            <div className="slds-form__row">
                <Field
                    recordObject={recordObject}
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    fieldName="name"
                    isEditable
                    hasOverride={record?.hasOverride?.name}
                    hasRevert
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required value={record?.name} errorText={fieldErrors?.name} />}
                />
            </div>

            <div className="slds-form__row">
                <Field mode={mode} value={record?.sourceName} label="Source" showStaticViewInEditMode={true} />
                <Field
                    mode={mode}
                    isEditable
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.scheduleReset} />}
                    label="Reprocess"
                    onEdit={onEdit}
                    body={<Toggle label="Reprocess" active={record?.scheduleReset} showStatus={true} setActive={() => setRecord((prev) => ({ ...prev, scheduleReset: !prev.scheduleReset }))} />}
                />
            </div>

            {(mode === "init" || mode === "view") && (
                <>
                    <div className="slds-form__row">
                        <Field mode={mode} value={record?.dataLastChangedOn} label="Latest Data" />
                        <Field mode={mode} value={record?.relevance} label="Relevance" />
                    </div>

                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Statistics</h3>
                    <div className="slds-form__row">
                        <Field mode={mode} value={record?.numRows} label="# Rows" />
                    </div>
                </>
            )}
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Object"
            recordModule="store"
            recordObject="container"
            overriddeFields={["name"]}
            record={record}
            showEdit={true}
            showCardActions={true}
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            cardBody={cardBody}
            cardActions={cardActions}
        />
    );
};

export default PsContainer2;
