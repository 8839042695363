// import { Auth } from "aws-amplify";
import RecordConstants from "../constants/RecordConstants";

export function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

// export const checkCurrentSession = async () => {
//     try {
//         const issCurrentSession = await Auth.currentSession();
//         return issCurrentSession;
//     } catch (error) {
//         console.error("Error checking current session:", error);
//         return null;
//     }
// };

export const getLocalDateTime = function (utcDatetime) {
    try {
        if (!utcDatetime) {
            return null;
        }

        // utcDatetime 2022-09-12T13:09:46.000Z
        const utcDate = new Date(utcDatetime);
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
        // Convert to local timezone, automatically takes the local timezone of the system
        const formattedLocalDatetime = localDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000'
        // formattedLocalDatetime 2022-09-12T14:09:46.000 //BST, so this did work

        return formattedLocalDatetime;
    } catch (err) {
        console.error(err);
    }
    return null;
};

export const getUTCDateTime = function (localDatetime) {
    try {
        if (!localDatetime) {
            return null;
        }

        // localDatetime 2022-09-12T14:09:46.000 //BST, so this did work

        const localDate = new Date(localDatetime);
        const formattedUtcDatetime = localDate.toISOString();

        // const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000)); // Convert to UTC, automatically takes the local timezone of the system
        // const formattedUtcDatetime = utcDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000Z'

        // formattedUtcDatetime 2022-09-12T13:09:46.000Z

        return formattedUtcDatetime;
    } catch (err) {
        console.error(err);
    }
    return null;
};

export function toastDetails(response) {
    const message = response?.data?.message || null;
    const reference = response?.data?.reference;
    const suggestion = response?.data?.suggestion;

    return (
        <>
            {message}
            {reference && (
                <>
                    <br /> {reference}
                </>
            )}
            {suggestion && (
                <>
                    <br />
                    {suggestion}
                </>
            )}
            {!message && !reference && !suggestion && "Unknown error"}
        </>
    );
}

export function truncateText(text, maxLength) {
    return text.length <= maxLength ? text : text.substring(0, maxLength) + "...";
}

export const auth = (regionNumber) => {
    return {
        region: process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`],
        userPoolId: process.env[`REACT_APP_COGNITO_USER_POOL_ID_REGION_${regionNumber}`],
        userPoolWebClientId: process.env[`REACT_APP_COGNITO_APP_CLIENT_ID_REGION_${regionNumber}`],
        mandatorySignIn: true,
    };
};

export const endpoints = (regionNumber) => {
    return [
        {
            name: "Application",
            endpoint: "https://" + process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`] + "." + process.env.REACT_APP_API_URL,
            region: process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`],
        },
    ];
};

export const toastErrorMessage = (response) => {
    const returnValue = response?.data || {};
    if (RecordConstants.SILENT_API_ERRORS.includes(returnValue.type)) {
        return null;
    }

    // some cases message is coming as an JWT token
    if (response?.data?.message?.includes("Authorization header: 'Bearer")) {
        return "No response from server";
    }

    return toastDetails(response) || "No response from server";
};

export const isEmptyOrSpaces = (str) => {
    return str == null || str.match(/^ *$/) !== null;
};

export const formattedPercentage = (value, digits) => {
    // TODO: is there no lightning class for this, e.g., <lightning:formattedNumber
    return value
        ? new Intl.NumberFormat("en-US", {
              style: "percent",
              maximumFractionDigits: digits,
          }).format(value)
        : "";
};

export const formattedNumber = (number) => {
    return <>{number ? <span>{number.toLocaleString()}</span> : number}</>;
};

export const formattedDateTime = (dateString) => {
    // TODO: can we use a dateformatting library instead please?
    if (!dateString) {
        return "";
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const regions = () => {
    const regionNumberCount = parseInt(process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT, 10);
    const regionOptions = Array.from({ length: regionNumberCount }, (v, i) => {
        const id = (i + 1).toString();
        const label = process.env[`REACT_APP_COGNITO_REGION_LABEL_${id}`];
        const value = id;
        return { id, label, value };
    });

    return regionOptions;
};
