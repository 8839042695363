import { Button, Input } from "@salesforce/design-system-react";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import PsRecord2 from "../ps-record/PsRecord2";
import { formattedDateTime, formattedNumber, getInnerSize } from "../../utils";
import { Mode, PsRecordPropsType, LinkAndChainInputType, ChainResponseType, ChainType, ChildToParentEventType, Key, Container, UpdateChainRequestType, CreateChainRequestType } from "../../types";
import Field from "../../ui/wrappers/Field";
import { checkRequiredField, sortFunction } from "../../utils/index2";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Toggle from "../../ui/Toggle";
import { RECORD_COLUMNS } from "./constants";
import RecordTable from "../../ui/tables/record-table/RecordTable";
import useToastContext from "../../context/useToastContext";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import FormItemWrapper from "../../ui/wrappers/FormItemWrapper";
import { ContainerType, KeyFilterType } from "./components/types";

const recordObject = "chain";
const recordModule = "store";
const defaultRecord = { name: "", custom: true, accept: true } as ChainType;

const PsChain2: React.FC<PsRecordPropsType> = ({ recordId, parentId, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<ChainType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [dataTableRecords, setDataTableRecords] = useState([] as LinkAndChainInputType[]);
    const [gridWidth, setGridWidth] = useState(null);
    const [leftKeySelected, setLeftKeySelected] = useState(null);
    const [leftKeyId, setLeftKeyId] = useState("");
    const [leftKey, setLeftKey] = useState(null);
    const [rightKeySelected, setRightKeySelected] = useState(null);
    const [rightKeyId, setRightKeyId] = useState("");
    const [rightKey, setRightKey] = useState(null);
    const [leftKeyFilters, setLeftKeyFilters] = useState(null);
    const [rightKeyFilters, setRightKeyFilters] = useState(null);
    // TO DO: Remove this after new PsNavigationInput comp
    const [cmpState, setCmpState] = useState({
        activeField: "",
        isExpanded: false,
    });

    const gridRef = useRef(null);

    // global toast
    const { addToast } = useToastContext();

    useEffect(() => {
        leftContainerFilterToParent();
    }, [parentId]);

    useEffect(() => {
        const currentGridRef = gridRef.current;

        const updateWidth = () => {
            if (currentGridRef) {
                const innerSize = getInnerSize(currentGridRef);
                setGridWidth(innerSize?.width);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (currentGridRef) {
            resizeObserver.observe(currentGridRef);
        }

        return () => {
            if (currentGridRef) {
                resizeObserver.unobserve(currentGridRef);
            }
        };
    }, []);

    const parseResponse = (response: ChainResponseType[]): ChainType[] => {
        return response.map(({ id, name, accept, leftContainer, rightContainer, relationship, lastRunOn, coverage, robustDistinct, relevance, overridden, custom, inputs }) => ({
            id,
            name: name || "",
            accept: !!accept,
            leftContainerName: leftContainer.name,
            rightContainerName: rightContainer.name,
            relationship,
            lastRunOn: formattedDateTime(lastRunOn),
            coverage: formattedNumber(coverage),
            robustDistinct: formattedNumber(robustDistinct),
            relevance: formattedNumber(relevance),
            overridden,
            custom: !!custom,
            inputs,
        }));
    };

    const parseUpdateRequest = (updatedRecord: ChainType): UpdateChainRequestType => {
        return (({ id, name, accept, overridden }) => ({ id, name, accept, overridden }))(updatedRecord);
    };

    const parseCreateRequest = (newRecord: ChainType): CreateChainRequestType => {
        return (({ name, type, accept, leftContainerId, rightContainerId, inputs }) => ({
            name,
            type,
            accept,
            leftContainerId,
            rightContainerId,
            inputs,
        }))(newRecord);
    };

    const updateUI = (record: ChainType) => {
        let chain = record || ({} as ChainType);
        let chainLinks = chain.inputs || ([] as LinkAndChainInputType[]);
        // sort by argOrder
        let orderBy = "argOrder";
        let orderFunc = sortFunction(chainLinks, orderBy);

        if (Array.isArray(chainLinks)) {
            chainLinks.sort(orderFunc(orderBy, true));
        }

        // set Key and Container details on records
        chainLinks.forEach((chainLink) => {
            const link = chainLink.link || ({} as LinkAndChainInputType);
            let linkKeys = link.inputs || [];

            // sort by (isLeft, argOrder)
            let orderBy = "argOrder";
            let orderFunc = sortFunction(linkKeys, orderBy);
            if (Array.isArray(linkKeys)) {
                linkKeys.sort(orderFunc(orderBy, true));
            }
            orderBy = "isLeft";
            orderFunc = sortFunction(linkKeys, orderBy);
            linkKeys.sort(orderFunc(orderBy, true));
            linkKeys.forEach((linkKey) => {
                const key = linkKey.key || ({} as Key);
                const container = key.container || ({} as Container);
                const source = container.source || ({} as { id: string; name: string });
                Object.assign(linkKey, {
                    name: (linkKey.isLeft ? "From: " : "To: ") + key.name,
                    keyName: key?.name,
                    robustDistinct: key.robustDistinct,
                    containerName: container.name,
                    sourceName: source.name,
                    _parentId: chainLink.id,
                });
            });
            Object.assign(chainLink, {
                name: link.name,
                robustDistinct: link.robustDistinct,
                object: "link",
                _children: linkKeys,
            });
        });

        let records = [];
        chainLinks.forEach((chainLink) => {
            records.push(chainLink);
            chainLink._children.forEach((child) => {
                records.push(child);
            });
        });
        // cmp.set("dataTableRecords", records);
        setDataTableRecords(records);
    };

    const notifyNavigation = (parentId: string, module: string, object: string, id: any = null) => {
        const navigationEvent = { parentId, module, obj: object, id, source: "record", type: "navigation" };
        childToParent(navigationEvent);
    };

    const handleRecordRowAction = (action: string, row: LinkAndChainInputType) => {
        const parentNav = parentId || "";
        switch (action) {
            case "details":
                notifyNavigation(null, recordModule, recordObject, row.id);
                break;
            case "viewSource":
                notifyNavigation("sources", "core", "source", row.key.container.source.id);
                break;
            case "viewContainer":
                notifyNavigation(row.key.container.source.id, "store", "container", row.key.container.id);
                break;
            case "viewKey":
                notifyNavigation(parentNav, "store", "key", row.key.id);
                break;
            default:
        }
    };

    const leftContainerFilterToParent = () => {
        if (parentId) {
            setLeftKeyFilters({ keys: { key: { containerId: parentId } } });
        } else {
            setLeftKeyFilters(null);
        }
    };

    const clearKeyFields = () => {
        setLeftKeySelected(null);
        setLeftKeyId(null);
        setLeftKey(null);
        setRightKeySelected(null);
        setRightKeyId(null);
        setRightKey(null);
    };

    const setRightKeyFilter = (updatedLeftKey: KeyFilterType) => {
        if (updatedLeftKey && updatedLeftKey.dataType && updatedLeftKey.dataType.dataFormat) {
            setRightKeyFilters({ keys: { key: { dataFormatId: updatedLeftKey.dataType.dataFormat.id } } });
        } else {
            setRightKeyFilters(null);
        }
    };

    const handleLeftKeyChange = (record: KeyFilterType) => {
        setLeftKey(record);
        setRightKeyFilter(record);
    };

    const handleRightKeyChange = (record: KeyFilterType) => {
        setRightKey(record);
        setRightKeyFilter(record);
    };

    const bubbleEvent = (event: ChildToParentEventType) => {
        let stopPropagation = false;

        if (!stopPropagation) {
            childToParent(event);
        }
    };

    const saveChainLink = () => {
        if (!leftKey || !rightKey) {
            addToast("warning", "Missing Field", 'Select both a "From" and a "To" Field');
            return;
        }
        const leftType = leftKey.dataType || {};
        const rightType = rightKey.dataType || {};
        if (!leftType.dataFormat || !rightType.dataFormat || leftType.dataFormat.id !== rightType.dataFormat.id) {
            addToast("warning", "Mismatching Formats", '"From" and "To" Fields must have the same data format');
            return;
        }
        const leftContainer = leftKey.container || {};
        const rightContainer = rightKey.container || {};
        // construct LinkKeys, Link and ChainLink records
        let updatedRecord = { ...record } || ({} as ChainType);
        let chainLinks = updatedRecord.inputs || ([] as LinkAndChainInputType[]);
        const numChainLinks = chainLinks.length;
        const name = leftKey.container?.name + "." + leftKey.name + " + " + rightKey.container?.name + "." + rightKey.name;
        const linkKeys = [
            {
                id: uuidv4(),
                isLeft: true,
                argOrder: 0,
                keyId: leftKey.id,
                key: leftKey,
            },
            {
                id: uuidv4(),
                isLeft: false,
                argOrder: 0,
                keyId: rightKey.id,
                key: rightKey,
            },
        ];

        const link = {
            name,
            type: "Custom Record",
            leftContainerId: leftContainer.id,
            rightContainerId: rightContainer.id,
            leftContainer,
            rightContainer,
            inputs: linkKeys,
        };

        const chainLink = { id: uuidv4(), argOrder: numChainLinks, link } as LinkAndChainInputType;

        // update Chain record
        if (numChainLinks === 0) {
            Object.assign(updatedRecord, {
                leftContainer,
                leftContainerId: leftContainer.id,
                leftContainerName: leftContainer.name,
            });
        }
        Object.assign(updatedRecord, {
            rightContainer,
            rightContainerId: rightContainer.id,
            rightContainerName: rightContainer.name,
        });
        chainLinks.push(chainLink);
        updatedRecord.inputs = chainLinks;

        // update filters
        setLeftKeyFilters({ keys: { key: { containerId: rightContainer.id } } });
        setRightKeyFilters(null);

        // update UI
        clearKeyFields();
        setRecord(updatedRecord);

        // the next Link's leftContainer has to match the current Link's rightContainer
        updateUI(updatedRecord);
    };

    const removeLastChainLink = () => {
        let updatedRecord = record || ({} as ChainType);
        let chainLinks = updatedRecord.inputs || [];
        let numChainLinks = chainLinks.length;
        if (numChainLinks) {
            chainLinks.pop();
            numChainLinks = chainLinks.length;
        }
        // update Chain.container fields
        if (numChainLinks) {
            let lastChainLink = chainLinks[numChainLinks - 1];
            let rightContainer = lastChainLink.link?.rightContainer || ({} as ContainerType);
            setLeftKeyFilters({ keys: { key: { containerId: rightContainer.id } } });
            Object.assign(updatedRecord, {
                rightContainer,
                rightContainerId: rightContainer.id,
                rightContainerName: rightContainer.name,
            });
        } else {
            leftContainerFilterToParent();
            Object.assign(updatedRecord, {
                leftContainer: null,
                leftContainerId: null,
                leftContainerName: null,
                rightContainer: null,
                rightContainerId: null,
                rightContainerName: null,
            });
        }

        // update UI
        clearKeyFields();
        setRecord(updatedRecord);
        updateUI(updatedRecord);
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    function displayValidationToast() {
        if (mode === "new" && (!record?.inputs || record?.inputs?.length === 0)) {
            addToast("error", "Missing Joins", "Paths must have at least one Join", "missingJoinsErrorId");
            return true;
        } else {
            return false;
        }
    }

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-m-top_medium">Path Details</h3>

            <div className="slds-form__row">
                {/* <!-- Name --> */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    isEditable
                    fieldName="name"
                    hasOverride={record?.hasOverride?.name}
                    hasRevert
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required={true} value={record?.name} errorText={fieldErrors?.name} />}
                />
            </div>

            <div className="slds-form__row">
                {/* Left Container  */}
                <Field mode={mode} value={record?.leftContainerName} label="From" showStaticViewInNewMode={true} showStaticViewInEditMode={true} />

                {/* Right Container */}
                <Field mode={mode} value={record?.rightContainerName} label="To" showStaticViewInNewMode={true} showStaticViewInEditMode={true} />
            </div>

            <div className="slds-form__row">
                {/* Custom  */}
                <Field mode={mode} value={<CheckAndCloseIcons selectedItem={record?.custom} />} label="Custom" showStaticViewInNewMode={true} showStaticViewInEditMode={true} />
                {/* Accept */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    isEditable
                    fieldName="accept"
                    hasOverride={record?.hasOverride?.accept}
                    hasRevert
                    onEdit={onEdit}
                    setFieldErrors={setFieldErrors}
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.accept} />}
                    label="Use in Pattern Discovery"
                    body={
                        <Toggle
                            label="Use in Pattern Discovery"
                            active={!!record?.accept}
                            showStatus={true}
                            setActive={() => setRecord((prev) => ({ ...prev, accept: !prev?.accept, hasOverride: { ...prev?.hasOverride, accept: true } }))}
                        />
                    }
                />
            </div>

            {(mode === "init" || mode === "view") && (
                <>
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Statistics</h3>

                    <div className="slds-form__row">
                        {/* Relationship */}
                        <Field mode={mode} value={record?.relationship} label="Relationship" />
                        {/* LastRunOn */}
                        <Field mode={mode} value={record?.lastRunOn} label="Last Run" />
                    </div>

                    <div className="slds-form__row">
                        {/* Coverage */}
                        <Field mode={mode} value={record?.coverage} label="Coverage" />
                        {/* RobustDistinct */}
                        <Field mode={mode} value={record?.robustDistinct} label="# Distinct" />
                    </div>

                    <div className="slds-form__row">
                        {/* Relevance */}
                        <Field mode={mode} value={record?.relevance} label="Relevance" />
                        <FormItemWrapper></FormItemWrapper>
                    </div>
                </>
            )}

            {(mode === "init" || mode === "view" || mode === "new") && (
                <>
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Joins</h3>
                    <p>&nbsp;</p>
                    <div className="slds-form__row">
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked" id="gridRef" ref={gridRef}>
                                <RecordTable
                                    columns={RECORD_COLUMNS}
                                    records={dataTableRecords}
                                    tableWidth={gridWidth}
                                    orderBy="argOrder"
                                    orderDirection="asc"
                                    onOrderBy={() => {}}
                                    onRowAction={handleRecordRowAction}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {mode === "new" && (
                <>
                    {/*  Left and Right Keys to add */}
                    <div className="slds-form__row">
                        <div className="slds-form__item" role="listitem">
                            <PsNavigationInput
                                label="From Field"
                                object="key"
                                sections={["keys"]}
                                selected={leftKeySelected}
                                value={leftKeyId}
                                record={leftKey}
                                filters={leftKeyFilters}
                                onChange={handleLeftKeyChange}
                                isExpanded={cmpState.isExpanded}
                                activeField={cmpState.activeField}
                                setParentCmpState={setCmpState}
                                showRequiredFieldError={false}
                                fieldErrors={{}}
                                childToParent={bubbleEvent}
                            />
                        </div>
                        <div className="slds-form__item" role="listitem">
                            <PsNavigationInput
                                label="To Field"
                                object="key"
                                sections={["keys"]}
                                selected={rightKeySelected}
                                value={rightKeyId}
                                record={rightKey}
                                filters={rightKeyFilters}
                                onChange={handleRightKeyChange}
                                disabled={!leftKey}
                                isExpanded={cmpState.isExpanded}
                                activeField={cmpState.activeField}
                                setParentCmpState={setCmpState}
                                showRequiredFieldError={false}
                                fieldErrors={{}}
                                childToParent={bubbleEvent}
                            />
                        </div>
                    </div>

                    {/* Add/Remove buttons */}
                    <div className="slds-form__row slds-m-top_small">
                        <FormItemWrapper>
                            <Button disabled={loading || !dataTableRecords || dataTableRecords.length === 0} label="Remove" title="Remove last Link" onClick={() => removeLastChainLink()} />
                            <Button disabled={loading || !leftKey || !rightKey} label="Add" title="Add new Link" onClick={() => saveChainLink()} variant="brand" />
                        </FormItemWrapper>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Path"
            recordModule={recordModule}
            recordObject={recordObject}
            overriddeFields={["name", "accept"]}
            record={record}
            defaultRecord={defaultRecord}
            showEdit={true}
            showDelete={true}
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            updateUI={updateUI}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseCreateRequest={parseCreateRequest}
            parseUpdateRequest={parseUpdateRequest}
            cardBody={cardBody}
            displayValidationToast={displayValidationToast}
        />
    );
};

export default PsChain2;
