import { useState, useEffect } from "react";
import { Card } from "@salesforce/design-system-react";
import { useSearchParams, useNavigate } from "react-router-dom";

import "../Pages.css";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import NewPasswordForm from "./components/NewPasswordForm";
import useToastContext from "../../context/useToastContext";
import useAuthContext from "../../context/useAuthContext";

const Login = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { handleLogin } = useAuthContext();
    const { addToast } = useToastContext();
    const [mode, setMode] = useState("login"); //login, resetPassword, newPassword
    const [cognitoUser, setCognitoUser] = useState(null);

    useEffect(() => {
        onPageReferenceChange();
    }, [searchParams]);

    const onPageReferenceChange = () => {
        var mode = searchParams.get("mode") || "login";
        setMode(mode.toLowerCase());
    };

    const completeLogin = (userId, instance) => {
        if (!userId) {
            addToast("error", "Error", "Unable to log in, please check your login details and try again");
            return;
        }
        handleLogin(userId, instance);
        navigate("/", { replace: true });
    };

    return (
        <div className="login-signup-container">
            <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
                {mode === "login" && <LoginForm setMode={setMode} completeLogin={completeLogin} setCognitoUser={setCognitoUser} />}
                {mode === "resetpassword" && <ResetForm setMode={setMode} />}
                {mode === "newpassword" && <NewPasswordForm completeLogin={completeLogin} cognitoUser={cognitoUser} />}
            </Card>
        </div>
    );
};

export default Login;
