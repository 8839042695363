import { Input } from "@salesforce/design-system-react";
import React, { useEffect, useRef, useState } from "react";

import PsRecord2 from "../ps-record/PsRecord2";
import { formattedDateTime, formattedNumber, getInnerSize } from "../../utils";
import { Mode, PsRecordPropsType, LinkResponseType, LinkType, LinkAndChainInputType, UpdateLinkRequestType } from "../../types";
import Field from "../../ui/wrappers/Field";
import { checkRequiredField, sortFunction } from "../../utils/index2";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Toggle from "../../ui/Toggle";
import { RECORD_COLUMNS } from "./constants";
import RecordTable from "../../ui/tables/record-table/RecordTable";

const recordObject = "link";
const recordModule = "store";

const PsLink2: React.FC<PsRecordPropsType> = ({ recordId, parentId, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<LinkType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [dataTableRecords, setDataTableRecords] = useState([] as LinkAndChainInputType[]);
    const [gridWidth, setGridWidth] = useState(null);

    const gridRef = useRef(null);

    useEffect(() => {
        const currentGridRef = gridRef.current;

        const updateWidth = () => {
            if (currentGridRef) {
                const innerSize = getInnerSize(currentGridRef);
                setGridWidth(innerSize?.width);
            }
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (currentGridRef) {
            resizeObserver.observe(currentGridRef);
        }

        return () => {
            if (currentGridRef) {
                resizeObserver.unobserve(currentGridRef);
            }
        };
    }, []);

    const parseResponse = (response: LinkResponseType[]): LinkType[] => {
        return response.map(({ id, name, leftContainer, rightContainer, relationship, lastRunOn, coverage, robustDistinct, overridden, accept, custom, inputs }) => ({
            id,
            name: name || "",
            leftContainerName: leftContainer?.name,
            rightContainerName: rightContainer?.name,
            leftContainerId: leftContainer?.id,
            rightContainerId: rightContainer?.id,
            leftSourceId: leftContainer?.source?.id,
            rightSourceId: rightContainer?.source?.id,
            relationship,
            lastRunOn: formattedDateTime(lastRunOn),
            coverage: formattedNumber(coverage),
            robustDistinct: formattedNumber(robustDistinct),
            overridden,
            accept: !!accept,
            custom: !!custom,
            inputs,
        }));
    };

    const parseUpdateRequest = (updatedRecord: LinkType): UpdateLinkRequestType => {
        return (({ id, name, overridden, accept }) => ({
            id,
            name,
            overridden,
            accept,
        }))(updatedRecord);
    };

    const updateUI = (record: LinkType) => {
        let link = record || ({} as LinkType);
        let linkKeys = link.inputs || ([] as LinkAndChainInputType[]);
        // sort by (isLeft, argOrder)
        let orderBy = "argOrder";
        let orderFunc = sortFunction(linkKeys, orderBy);
        if (Array.isArray(linkKeys)) {
            linkKeys.sort(orderFunc(orderBy, true));
        }
        orderBy = "isLeft";
        orderFunc = sortFunction(linkKeys, orderBy);
        linkKeys = (linkKeys as LinkAndChainInputType[]).sort(orderFunc(orderBy, true));
        // set Key and Container details on records
        linkKeys.forEach((linkKey) => {
            let key = linkKey.key || ({} as any);
            const container = key.container || {};
            const source = container.source || {};
            Object.assign(linkKey, {
                name: (linkKey.isLeft ? "From: " : "To: ") + key.name,
                keyName: key.name,
                robustDistinct: key.robustDistinct,
                containerId: container.id,
                containerName: container.name,
                sourceId: source.id,
                sourceName: source.name,
            });
        });
        setDataTableRecords(linkKeys);
    };

    const notifyNavigation = (parentId: string, module: string, object: string, id: any = null) => {
        const navigationEvent = { parentId, module, obj: object, id, source: "record", type: "navigation" };
        childToParent(navigationEvent);
    };

    const handleRecordRowAction = (action: string, row: LinkAndChainInputType) => {
        const parentNav = parentId || "";
        switch (action) {
            case "details":
                notifyNavigation(null, recordModule, recordObject, row.id);
                break;
            case "viewSource":
                notifyNavigation("sources", "core", "source", row.key.container.source.id);
                break;
            case "viewContainer":
                notifyNavigation(row.key.container.source.id, "store", "container", row.key.container.id);
                break;
            case "viewKey":
                notifyNavigation(parentNav, "store", "key", row.key.id);
                break;
            default:
        }
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-m-top_medium">Join Details</h3>

            <div className="slds-form__row">
                {/* <!-- Name --> */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    isEditable
                    fieldName="name"
                    hasOverride={record?.hasOverride?.name}
                    hasRevert
                    checkValidity={checkRequiredField}
                    setFieldErrors={setFieldErrors}
                    body={<Input name="name" autoComplete="off" label="Name" required={true} value={record?.name} errorText={fieldErrors?.name} />}
                />
            </div>

            <div className="slds-form__row">
                {/* Left Container  */}
                <Field mode={mode} value={record?.leftContainerName} label="From" showStaticViewInEditMode={true} showStaticViewInNewMode={true} />
                {/* Right Container */}
                <Field mode={mode} value={record?.rightContainerName} label="To" showStaticViewInEditMode={true} showStaticViewInNewMode={true} />
            </div>

            <div className="slds-form__row">
                {/* Custom  */}
                <Field mode={mode} value={<CheckAndCloseIcons selectedItem={record?.custom} />} label="Custom" showStaticViewInEditMode={true} showStaticViewInNewMode={true} />
                {/* Accept */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    isEditable
                    fieldName="accept"
                    hasOverride={record?.hasOverride?.accept}
                    hasRevert
                    onEdit={onEdit}
                    setFieldErrors={setFieldErrors}
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.accept} />}
                    label="Use in Pattern Discovery"
                    body={
                        <Toggle
                            label="Use in Pattern Discovery"
                            active={record?.accept}
                            showStatus={true}
                            setActive={() => setRecord((prev) => ({ ...prev, accept: !prev.accept, hasOverride: { ...prev.hasOverride, accept: true } }))}
                        />
                    }
                />
            </div>

            {(mode === "init" || mode === "view") && (
                <>
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Statistics</h3>

                    <div className="slds-form__row">
                        {/* Relationship */}
                        <Field mode={mode} value={record?.relationship} label="Relationship" />
                        {/* LastRunOn */}
                        <Field mode={mode} value={record?.lastRunOn} label="Last Run" />
                    </div>

                    <div className="slds-form__row">
                        {/* Coverage */}
                        <Field mode={mode} value={record?.coverage} label="Coverage" />
                        {/* RobustDistinct */}
                        <Field mode={mode} value={record?.robustDistinct} label="# Distinct" />
                    </div>

                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Fields</h3>
                    <p>&nbsp;</p>
                    <div className="slds-form__row">
                        <div className="slds-form__item" role="listitem">
                            <div className="slds-form-element slds-form-element_stacked" id="gridRef" ref={gridRef}>
                                <RecordTable
                                    columns={RECORD_COLUMNS}
                                    records={dataTableRecords}
                                    tableWidth={gridWidth}
                                    orderBy="argOrder"
                                    orderDirection="asc"
                                    onOrderBy={() => {}}
                                    onRowAction={handleRecordRowAction}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Join"
            recordModule={recordModule}
            recordObject={recordObject}
            overriddeFields={["name", "accept"]}
            record={record}
            showEdit={true}
            showDelete={true}
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            updateUI={updateUI}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            cardBody={cardBody}
        />
    );
};

export default PsLink2;
