import React, { useState } from "react";
import { Input } from "@salesforce/design-system-react";

import PsRecord2 from "../ps-record/PsRecord2";
import { KeyResponseType, KeyType, Mode, PsRecordPropsType, UpdateKeyRequestType } from "../../types";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Field from "../../ui/wrappers/Field";
import Toggle from "../../ui/Toggle";
import { getRecordAPI } from "../../services/api";
import { formattedPercentage, revertOverride } from "../../utils";
import Record from "../../helpers/recordLayer";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import { formattedNumber, formattedDateTime, setOverride } from "../../utils";
import TextArea2 from "./components/TextArea2";
import { getBreadCrumb, getTitle } from "./components/utils";
import { FIELD_ERROR_MESSAGES } from "../../constants";
import { checkRequiredField } from "../../utils/index2";

const PsKey2: React.FC<PsRecordPropsType> = ({ recordId, parentId, childToParent, parentToChildEvent }) => {
    const [mode, setMode] = useState<Mode>("init");
    const [record, setRecord] = useState<KeyType>();
    const [fieldErrors, setFieldErrors] = useState({} as { [key: string]: string });
    const [loading, setLoading] = useState<boolean>(false);
    const [cmpState, setCmpState] = useState({
        activeField: "",
        isExpanded: false,
    });

    const parseResponse = (response: KeyResponseType[]): KeyType[] => {
        const parsedResponse = response.map(
            ({
                id,
                name,
                type,
                container,
                dataType,
                relevance,
                dataLastChangedOn,
                parseOptions,
                dataTypeDetails,
                numRows,
                numValues,
                robustDistinct,
                robustMin,
                robustMax,
                robustRange,
                overridden,
                scheduleReset,
                accept,
            }) => ({
                id,
                name: name || "",
                type,
                containerId: container.id,
                containerName: container.name,
                sourceId: container.source.id,
                sourceName: container.source.name,
                dataType,
                dataTypeName: dataType.name,
                dataTypeId: dataType.id,
                dataTypeSelected: Record.nameFromDetails("types", "dataType", dataType.id),
                relevance: formattedPercentage(relevance),
                dataLastChangedOn: formattedDateTime(dataLastChangedOn),
                parseOptions,
                dataTypeDetails,
                numRows: formattedNumber(numRows),
                numValues: formattedNumber(numValues),
                robustDistinct: formattedNumber(robustDistinct),
                robustMin: formattedNumber(robustMin),
                robustMax: formattedNumber(robustMax),
                robustRange: formattedNumber(robustRange),
                overridden,
                scheduleReset: !!scheduleReset,
                accept: !!accept,
                parseOptionsText: "",
                dataTypeDetailsText: "",
            })
        );

        parsedResponse.forEach((record) => {
            record.dataType.title = getTitle(getBreadCrumb("dataType", record.dataType));
            record.dataTypeDetailsText = record.dataTypeDetails ? JSON.stringify(record.dataTypeDetails, null, 2) : null;
            record.parseOptionsText = record.parseOptions ? JSON.stringify(record.parseOptions, null, 2) : null;
        });

        return parsedResponse;
    };

    // This overrides the default PsRecord function
    const parseUpdateRequest = (updatedRecord: KeyType): UpdateKeyRequestType => {
        // valid JSON already checked during 'onblur' for textareas
        updatedRecord.parseOptions = updatedRecord.parseOptionsText ? JSON.parse(updatedRecord.parseOptionsText) : null;
        updatedRecord.dataTypeDetails = updatedRecord.dataTypeDetailsText ? JSON.parse(updatedRecord.dataTypeDetailsText) : null;

        return (({ id, name, dataTypeId, parseOptions, dataTypeDetails, overridden, scheduleReset, accept }) => ({
            id,
            name,
            dataTypeId,
            parseOptions,
            dataTypeDetails,
            overridden,
            scheduleReset,
            accept,
        }))(updatedRecord);
    };

    const onEdit = () => {
        setFieldErrors({});
        setMode("edit");
    };

    const handleOverride = (field: string, value: any) => {
        let overridenRecord = { ...record };
        if (["dataTypeId", "dataTypeDetails", "parseOptions"].includes(field)) {
            overridenRecord.scheduleReset = true;
        }
        if (field === "dataTypeId") {
            overridenRecord = { ...overridenRecord, dataType: value, dataTypeId: value.id };
        } else {
            overridenRecord = { ...overridenRecord, [field + "Text"]: value };
        }

        if (!value && field in fieldErrors && fieldErrors[field as keyof typeof fieldErrors]) {
            setFieldErrors((prev) => ({ ...prev, [field]: "" }));
        }

        overridenRecord = setOverride(overridenRecord, field);
        setRecord({ ...overridenRecord });
    };

    function revertDataType() {
        let revertedRecord = { ...record };
        const dataTypeId = revertedRecord.original.dataTypeId;
        const onSuccess = function (response: any) {
            let dataType = response[0];

            dataType.title = getTitle(getBreadCrumb("dataType", dataType));

            Object.assign(revertedRecord, {
                dataType,
                dataTypeName: dataType.name,
                dataTypeId: dataType.id,
                dataTypeSelected: Record.nameFromDetails("types", "dataType", dataType.id),
                hasOverride: { ...revertedRecord.hasOverride, dataTypeId: false },
            });

            setRecord({ ...revertedRecord });
        };
        const onError = function () {
            Object.assign(revertedRecord, {
                dataType: null,
                dataTypeName: null,
                dataTypeId: null,
                dataTypeSelected: null,
            });
            setRecord({ ...revertedRecord });
        };
        if (dataTypeId) {
            getRecordAPI({ module: "core", object: "datatype", recordId: dataTypeId }, onSuccess, onError);
        } else {
            onError();
        }
    }

    function checkJsonInput(field: string, value: string) {
        try {
            if (value) {
                JSON.parse(value);
            }

            setFieldErrors((prev) => ({ ...prev, [field]: "" }));
        } catch (err) {
            setFieldErrors((prev) => ({ ...prev, [field]: FIELD_ERROR_MESSAGES.JSON_FORMAT }));
        }
    }

    function revertJson(field: string) {
        let revertedRecord = { ...record };
        revertedRecord = revertOverride(revertedRecord, field);
        revertedRecord = {
            ...revertedRecord,
            [field + "Text"]: revertedRecord[field as keyof typeof revertedRecord] ? JSON.stringify(revertedRecord[field as keyof typeof revertedRecord], null, 2) : null,
        };
        setRecord({ ...revertedRecord });

        // remove errorText message
        checkJsonInput(field, "");
    }

    // record card body-content
    const cardBody = (
        <div className="slds-form slds-var-m-around_medium" role="list">
            <h3 className="slds-section-title--divider slds-var-m-top_medium">Field Details</h3>
            <div className="slds-form__row">
                {/* <!-- Name --> */}
                <Field
                    setRecord={setRecord}
                    mode={mode}
                    value={record?.name}
                    label="Name"
                    onEdit={onEdit}
                    fieldName="name"
                    isEditable={true}
                    hasOverride={record?.hasOverride?.name}
                    setFieldErrors={setFieldErrors}
                    hasRevert={true}
                    checkValidity={checkRequiredField}
                    body={<Input autoComplete="off" label="Name" required={true} value={record?.name} errorText={fieldErrors.name} />}
                />
            </div>

            <div className="slds-form__row">
                {/* <!-- Source --> */}
                <Field mode={mode} value={record?.sourceName} label="Source" />
                {/* <!-- Container --> */}
                <Field mode={mode} value={record?.containerName} label="Container" />
            </div>

            <div className="slds-form__row">
                {/* <!-- Type --> */}
                <Field mode={mode} value={record?.type} label="Type" showStaticViewInEditMode={true} />

                {/* <!-- Accept --> */}
                <Field
                    mode={mode}
                    isEditable={true}
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.accept} />}
                    label="Use in Pattern Discovery"
                    onEdit={onEdit}
                    body={<Toggle label="Use in Pattern Discovery" active={record?.accept} showStatus={true} setActive={() => setRecord((prev) => ({ ...prev, accept: !prev.accept }))} />}
                />
            </div>

            {mode !== "edit" && (
                <div className="slds-form__row">
                    {/* DataLastChangedOn */}
                    <Field mode={mode} value={record?.dataLastChangedOn} label="Latest Data" />
                    {/* Relevance */}
                    <Field mode={mode} value={record?.relevance} label="Relevance" />
                </div>
            )}

            <h3 className="slds-section-title--divider slds-var-m-top_medium">Data</h3>
            <div className="slds-form__row">
                {/* <!-- DataType --> */}
                <Field
                    mode={mode}
                    isEditable={true}
                    value={record?.dataTypeName}
                    label="Data Type"
                    onEdit={onEdit}
                    fieldName="dataTypeId"
                    hasOverride={record?.hasOverride?.dataTypeId}
                    revertFunction={revertDataType}
                    body={
                        <PsNavigationInput
                            label="Data Type"
                            object="dataType"
                            sections={["types"]}
                            selected={record?.dataTypeSelected}
                            value={record?.dataTypeId}
                            record={record?.dataType}
                            onChange={handleOverride}
                            isExpanded={cmpState.isExpanded}
                            activeField={cmpState.activeField}
                            setParentCmpState={setCmpState}
                            showRequiredFieldError={false}
                            fieldErrors={fieldErrors}
                            childToParent={childToParent}
                            setRecord={setRecord}
                        />
                    }
                />

                {/* <!-- ScheduleReset --> */}
                <Field
                    mode={mode}
                    isEditable={true}
                    value={mode === "edit" ? null : <CheckAndCloseIcons selectedItem={record?.scheduleReset} />}
                    label="Reprocess"
                    onEdit={onEdit}
                    body={<Toggle label="Reprocess" active={record?.scheduleReset} showStatus={true} setActive={() => setRecord((prev) => ({ ...prev, scheduleReset: !prev.scheduleReset }))} />}
                />
            </div>

            <div className="slds-form__row">
                {/* <!-- Parse Options --> */}
                <Field
                    mode={mode}
                    isEditable={true}
                    value={record?.parseOptionsText}
                    label="Format Options"
                    onEdit={onEdit}
                    fieldName="parseOptions"
                    hasOverride={record?.hasOverride?.parseOptions}
                    setRecord={setRecord}
                    revertFunction={revertJson}
                    body={
                        <TextArea2
                            handleOverride={handleOverride}
                            checkJsonInput={checkJsonInput}
                            record={record}
                            fieldErrors={fieldErrors}
                            field="parseOptions"
                            label="Format Options"
                            value="parseOptionsText"
                        />
                    }
                />

                {/* <!-- Data Type Details --> */}
                <Field
                    mode={mode}
                    isEditable={true}
                    value={record?.dataTypeDetailsText}
                    label="Type Options"
                    onEdit={onEdit}
                    fieldName="dataTypeDetails"
                    hasOverride={record?.hasOverride?.dataTypeDetails}
                    setRecord={setRecord}
                    revertFunction={revertJson}
                    body={
                        <TextArea2
                            handleOverride={handleOverride}
                            checkJsonInput={checkJsonInput}
                            record={record}
                            fieldErrors={fieldErrors}
                            field="dataTypeDetails"
                            label="Type Options"
                            value="dataTypeDetailsText"
                        />
                    }
                />
            </div>
            {(mode === "init" || mode === "view") && (
                <>
                    <h3 className="slds-section-title--divider slds-var-m-top_medium">Statistics</h3>

                    <div className="slds-form__row">
                        {/* NumRows */}
                        <Field mode={mode} value={record?.numRows} label="# Rows" />

                        {/* NumValues */}
                        <Field mode={mode} value={record?.numValues} label="# Values" />
                    </div>

                    <div className="slds-form__row">
                        {/* RobustMin */}
                        <Field mode={mode} value={record?.robustMin} label="Minimum" />

                        {/* RobustMax */}
                        <Field mode={mode} value={record?.robustMax} label="Maximum" />
                    </div>

                    <div className="slds-form__row">
                        {/* Robust Distinct */}
                        <Field mode={mode} value={record?.robustDistinct} label="# Distinct" />

                        {/* RobustRange */}
                        <Field mode={mode} value={record?.robustRange} label="Range" />
                    </div>
                </>
            )}
        </div>
    );

    return (
        <PsRecord2
            recordLabel="Field"
            recordModule="store"
            recordObject="key"
            overriddeFields={["name", "accept", "dataTypeId", "parseOptions", "dataTypeDetails"]}
            record={record}
            showEdit={true}
            showCardActions={true}
            mode={mode}
            recordId={recordId}
            parentId={parentId}
            parentToChildEvent={parentToChildEvent}
            fieldErrors={fieldErrors}
            setMode={setMode}
            onEdit={onEdit}
            setRecord={setRecord}
            setFieldErrors={setFieldErrors}
            loading={loading}
            setLoading={setLoading}
            childToParent={childToParent}
            parseResponse={parseResponse}
            parseUpdateRequest={parseUpdateRequest}
            cardBody={cardBody}
        />
    );
};

export default PsKey2;
